<template>
  <div>
    <banner
      title="Reservas"
      type="banner--admin"
      :options="{padding: '30px'}">
    </banner>
    <div class="pt-3 px-7">
      <booking-filters :params="params" @search="search" @clean="clean"></booking-filters>
      <div v-if="fairName" class="row">
        <div class="col-12 text-center red--text">
          <h2>{{fairName}}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <data-table ref="bookings" :url="url" :params="params" :headers="headers">
            <template v-slot:item.identification="{ item }">
              <span class="text-uppercase"> {{ item.identification }} </span>
            </template>

            <template v-slot:item.companyName="{ item }">
              <span class="text-uppercase"> {{ item.companyName }} </span>
            </template>

            <template v-slot:item.totalValue="{ item }">
              {{ (item.countryName === 'Colombia') ? '$' : '' }} {{ calculateWithTax(item) | currency }}
              {{ (item.countryName !== 'Colombia') ? 'USD' : '' }}
            </template>

            <template v-slot:item.firstContactName="{ item, attrs }">
              <v-btn
                class="ma-2 elevation-0"
                v-bind="attrs"
                @click="showContactInfo(item)"
                x-small
                color="primary"
              >
                <v-icon
                  center
                  x-small
                  color="white"
                >
                  fa fa-eye
                </v-icon>
              </v-btn>
            </template>

            <template v-slot:item.standsAmount="{ item, attrs }">
              <v-btn
                class="ma-2 elevation-0"
                v-bind="attrs"
                @click="showStandsInfo(item)"
                x-small
                color="primary"
              >
                <v-icon
                  center
                  x-small
                  color="white"
                >
                  fa fa-eye
                </v-icon>
              </v-btn>
              <span class="text-uppercase"> {{ item.standsAmount }} </span>
            </template>

            <template v-slot:item.currentStatus="{ item }">
              <v-chip
                :color="bookingStatus[item.currentStatus].color"
                dark
              >
                {{ bookingStatus[item.currentStatus].description }}
              </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip v-if="item.currentStatus === bookingStatus.new.code" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-2 elevation-0"
                    v-bind="attrs"
                    v-on="on"
                    @click="approve(item)"
                    small
                    color="secondary"
                  >
                    <v-icon
                      center
                      dark
                    >
                      fa fa-check
                    </v-icon>
                  </v-btn>
                </template>
                <span>Aprobar reserva</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-2 elevation-0"
                    v-bind="attrs"
                    v-on="on"
                    @click="reject(item)"
                    small
                    color="red"
                  >
                    <v-icon
                      center
                      color="white"
                    >
                      fa fa-times
                    </v-icon>
                  </v-btn>
                </template>
                <span>Rechazar reserva</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    class="ma-2 elevation-0"
                    v-bind="attrs"
                    v-if="item.currentStatus === bookingStatus.successful.code"
                    v-on="on"
                    @click="generateAgreement(item)"
                    small
                    color="primary"
                  >
                    <v-icon
                      dark
                      center
                      x-small
                    >
                      fa fa-file-pdf
                    </v-icon>
                  </v-btn>
                </template>
                <span>Contrato</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-2"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="showAudit(item)"
                    small
                    color="primary"
                  >
                    <v-icon
                      center
                      small
                      dark
                    >
                      fa fa-info-circle
                    </v-icon>
                  </v-btn>
                </template>
                <span>Auditoria</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.currentStatus !== 'new' && item.currentStatus !== 'rejected'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-2"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    small
                    color="primary"
                    @click="sendCredentials(item)"
                  >
                    <v-icon
                      center
                      small
                      dark
                    >
                      fa fa-share
                    </v-icon>
                  </v-btn>
                </template>
                <span>Reenviar credenciales</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-2"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    small
                    color="primary"
                    @click="showBookingInfo(item)"
                  >
                    <v-icon
                      center
                      small
                      dark
                    >
                      fas fa-search
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ver reserva</span>
              </v-tooltip>
            </template>
          </data-table>
        </div>
      </div>
      <contact-info-modal ref="contactInfoModal"/>
      <stands-info-modal ref="standsInfoModal"/>
      <audit-info-modal ref="auditInfoModal"/>
    </div>
  </div>
</template>
<script>
import DataTable from '@/components/data-table/DataTable'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import { AGREEMENTS_URL, BOOKINGS_URL } from '@/constants/ServicesConstants'
import headers from '@/views/private/bookings/data/Headers'
import BookingFilters from '@/views/private/bookings/components/BookingFilters'
import BOOKING_STATUS from '@/constants/BookingStatusContants'
import notificationMixin from '@/mixins/notificationMixin'
import loaderMixin from '@/mixins/loaderMixin'
import sessionMixin from '@/mixins/sessionMixin'
import ContactInfoModal from '@/views/private/bookings/components/ContactInfoModal'
import StandsInfoModal from '@/views/private/bookings/components/StandstInfoModal'
import AuditInfoModal from '@/views/private/bookings/components/AuditInfoModal'
import Banner from '@/components/banner/Banner'

export default {
  name: 'Bookings',
  data () {
    return {
      bookingId: null,
      contactInfo: null,
      fairName: null,
      headers,
      bookingStatus: BOOKING_STATUS,
      url: BOOKINGS_URL,
      params: {
        identification: null,
        bookingStatus: null,
        companyName: null,
        countryId: null,
        fairId: null,
        placeId: null,
        standId: null,
        fromArea: null,
        toArea: null,
        startDate: null,
        endDate: null
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (user) {
      if (sessionMixin.methods.isAdmin(user.roleId) || sessionMixin.methods.isSuperAdmin(user.roleId)) {
        next()
      } else {
        next({ path: '/error-403' })
      }
    } else {
      next({ path: '/' })
    }
  },
  methods: {
    search (fairName) {
      this.fairName = fairName
      this.$refs.bookings.getDataFromApi()
    },
    showBookingInfo (item) {
      this.$router.push({ path: `/ferias/${item.fairId}/reservas/${item.id}` })
    },
    showContactInfo (item) {
      this.$refs.contactInfoModal.open(item.id)
    },
    showAudit (item) {
      this.$refs.auditInfoModal.open(item.id)
    },
    showStandsInfo (item) {
      this.$refs.standsInfoModal.open(item.id)
    },
    async approve (item) {
      try {
        this.showLoader()
        await this.post(BOOKINGS_URL + '/' + item.id + '/approve')
        this.hideLoader()
        this.search()
        this.showSuccess('La reserva fue aprobada correctamente')
      } catch (error) {
        this.hideLoader()
        this.showError('Error al aprobar la reserva')
      }
    },
    async generateAgreement (item) {
      try {
        this.showLoader()
        const response = await this.get(AGREEMENTS_URL + '/' + item.id, { responseType: 'blob' })
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'REN_contrato_' + item.identification + '.pdf')
        link.click()
      } catch (error) {
        this.showError()
      } finally {
        this.hideLoader()
      }
    },
    async reject (item) {
      try {
        this.showLoader()
        await this.post(BOOKINGS_URL + '/' + item.id + '/reject')
        this.hideLoader()
        this.search()
        this.showSuccess('La reserva fue rechazada correctamente')
      } catch (error) {
        this.hideLoader()
        this.showError('Error al rechazar la reserva')
      }
    },
    async sendCredentials (item) {
      try {
        this.showLoader()
        await this.post(BOOKINGS_URL + '/' + item.id + '/credentials')
        this.hideLoader()
        this.showSuccess('Credenciales reenviadas correctamente')
      } catch (error) {
        this.hideLoader()
        this.showError('Error al reenviar las credenciales')
      }
    },
    clean () {
      this.params.identification = null
      this.params.bookingStatus = null
      this.params.companyName = null
      this.params.countryId = null
      this.params.fairId = null
      this.params.placeId = null
      this.params.standId = null
      this.params.fromArea = null
      this.params.toArea = null
      this.params.startDate = null
      this.params.endDate = null
      this.fairName = null
      this.search()
    },
    calculateWithTax (item) {
      if (item && item.countryName === 'Colombia') {
        return item.standsCopTotalValue * 1.19
      }
      return item.standsUsdTotalValue * 1.19
    }
  },
  mixins: [
    crudServiceMixin, notificationMixin, loaderMixin
  ],
  components: {
    Banner,
    AuditInfoModal,
    StandsInfoModal,
    ContactInfoModal,
    BookingFilters,
    DataTable
  }
}
</script>

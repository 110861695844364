export const BOOKING_STATUS = {
  new: {
    code: 'new',
    description: 'Nueva',
    color: 'black'
  },
  successful: {
    code: 'successful',
    description: 'Completa',
    color: 'secondary'
  },
  incomplete: {
    code: 'incomplete',
    description: 'Incompleta',
    color: 'primary'
  },
  rejected: {
    code: 'rejected',
    description: 'Rechazada',
    color: 'error'
  }
}
export default BOOKING_STATUS
